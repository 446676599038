import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { googleIcon } from "../../Assets/Images/Images";
import { newSignUp, setLoginData } from "../../Features/userSlice";
import { simpleValidationCheck } from "../../Functions/componentsFunctions";
import { compareDataToObjValue, uniqueArr } from "../../Functions/helper";
import s from "./SignUpForm.module.scss";
import { openSignWithGooglePopUp } from "./SignUpWithGoogle/SignUpWithGooglePopup";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { usersData } = useSelector((state) => state.user);
  const username = useRef("");
  const emailOrPhone = useRef("");
  const password = useRef("");
  let isSignUpWithGooglePressed = false;

  function signUp(e) {
    const inputs = e.target.querySelectorAll("input");
    const formDataObj = new FormData(e.target);
    const formData = {};
    // Set keys and values from formDataObj to formData
    for (let pair of formDataObj.entries()) formData[pair[0]] = pair[1];
    
    e.preventDefault();
    const isFormValid = simpleValidationCheck(inputs);
    
    if (isFormValid) {
      const isUserAlreadySignedUp = compareDataToObjValue(
        usersData,
        formData,
        "emailOrPhone"
      );
      if (isUserAlreadySignedUp) return;
      
      const uniqueUsersData = uniqueArr([...usersData, formData]);
      dispatch(newSignUp(uniqueUsersData));
      dispatch(setLoginData(formData));
      navigateTo("/", { replace: true });
    }
  }

  function handleSignUpWithGoogle() {
    if (isSignUpWithGooglePressed) return;
    isSignUpWithGooglePressed = true;
    openSignWithGooglePopUp();
    setDefaultSignUpData();
  }

  function setDefaultSignUpData() {
    const defaultLoginData = {
      username: "Lily Watson",
      emailOrPhone: "lily.wastons@gmail.com",
      password: "random-password1234",
      isSignIn: true,
    };
    setTimeout(() => {
      navigateTo("/");
      isSignUpWithGooglePressed = false;
      setTimeout(() => dispatch(setLoginData(defaultLoginData)), 500);
    }, 2500);
  }

  return (
    <form className={s.form} onSubmit={signUp}>
      <h2>Create an account</h2>
      <p>Enter your details below</p>
      
      <div className={s.inputs}>
        <input
          type="text"
          name="username"
          placeholder="Username"
          onChange={(e) => (username.current = e.target.value)}
          required
        />
        <input
          type="text"
          name="emailOrPhone"
          placeholder="Email or phone number"
          onChange={(e) => (emailOrPhone.current = e.target.value)}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => (password.current = e.target.value)}
          required
        />
      </div>

      <div className={s.checkboxes}>
        <label className={s.checkbox}>
          <input
            type="checkbox"
            name="newsletter"
            defaultChecked={false}
          />
          <span>Subscribe to Newsletter</span>
        </label>
        
        <label className={s.checkbox}>
          <input
            type="checkbox"
            name="terms"
            defaultChecked={false}
          />
          <span>I agree to the <NavLink to="/termsandconditions" className={s.termsLink}>terms and conditions</NavLink></span>
        </label>
      </div>

      <div className={s.buttons}>
        <button type="submit" className={s.createAccBtn}>
          Create Account
        </button>
        
        <button
          type="button"
          className={s.signUpBtn}
          onClick={handleSignUpWithGoogle}
        >
          <img src={googleIcon} alt="Google" />
          Sign up with Google
        </button>
        
        <p>
          <span>Already have account?</span>
          <NavLink to="/login">Log in</NavLink>
        </p>
      </div>
    </form>
  );
};

export default SignUpForm;